body {
  font-family: "Roboto", sans-serif !important;
}

.title {
  color: rgb(146, 200, 235);
  font-weight: 700;
}

.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  color: red;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: bold;
}
