.CustomerSalesTable-total {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.CustomerSalesTable-total p {
  margin: 1rem 3rem;
  font-weight: bold;
}
