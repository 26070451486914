.Sales {
  position: relative;
  margin: 0;
  padding: 4rem;
  background-color: #f3f3f3;
}

.WorkOrders-searchbar-container input {
  margin: 1rem 0;
  padding: 0.8rem 2rem;
  width: 100%;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 25px;
  transition: all 0.1s ease-in-out;
}

@media all and (max-width: 1024px) {
  .Sales {
    padding: 4rem 0.2rem 0.2rem 0.2rem;
  }
}
