.Admin {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  width: calc(100vw-3rem);
  background-color: #f6f6f6;
}

.Admin-container {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 50%;
  width: 50%;
}

.Admin-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Admin-header button {
  margin-left: 4rem;
}

.Admin .Collapsible {
  margin-top: 5rem;
  width: 100%;
}

.Admin-table {
  width: 100%;
}

.Admin tr:nth-child(even) {
  background-color: #f2f2f2;
}

.Admin-input-field {
  border: none;
  outline: none;
}

.Admin-button-container {
  margin-top: 1rem;
}

.Admin-button-container button {
  margin-right: 1rem;
}

.Admin-btn {
  margin-top: 1rem;
  background-color: rgb(146, 200, 235);
  color: white;
  transition: all 0.2s ease-in-out;
}

.Admin-btn:hover {
  background-color: rgb(0, 153, 255);
  color: white;
  transition: all 0.2s ease-in-out;
}

.Admin-delete {
  transition: all 0.2s ease-in-out;
  font-size: 0.8rem;
}

.Admin-delete:hover {
  color: red;
}
