.FormCustomer {
  margin: 1rem auto;
}

.FormCustomer-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0;
  width: 100%;
}

.FormCustomer-successful-message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.FormCustomer-successful-message i {
  margin: 1rem;
  padding: 4rem;
  color: green;
  font-size: 5rem;
  border: 1rem solid green;
  border-radius: 50%;
}

.FormCustomer-successful-message p {
  margin-bottom: 2rem;
  color: green;
  font-weight: bold;
}

.FormCustomer-success-message-btn {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.FormCustomer-success-message-btn button {
  margin: 0.2rem;
  font-size: 0.8rem;
  width: 100%;
}

.FormSaleDoor-door-section {
  margin: 0 0 3rem 0;
}

.FormSaleDoor-door-section .Collapsible__contentInner {
  background-color: #eeeeee;
}
