.Dashboard {
  position: relative;
  margin: 0;
  padding: 4rem;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dashboard-overview-section {
  width: 100%;
}

.Dashboard-overview-card-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: #f3f3f3 !important;
  margin: auto;
  margin-bottom: 4rem;
  width: 100%;
}

.Dashboard-overview-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  height: 20vw;
  width: 20vw;
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3%;
  transition: all 0.2s ease-in-out;
}

.Dashboard-overview-card h1 {
  font-weight: 900;
  font-size: 4rem;
}

.Dashboard-overview-card p {
  position: absolute;
  bottom: 0;
  font-size: 1.5rem;
}

.Dashboard-overview-card:hover {
  transform: scale(1.1);
  box-shadow: 10px 10px 37px -16px rgba(212, 209, 209, 1);
}

.nav-link {
  font-weight: 700;
}

.active {
  color: white !important;
  font-weight: 700;
}

.Dashboard-calendar-section {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
}

.Dashboard-calendar-table {
  margin: auto;
  border-spacing: 0 1rem;
  border-collapse: unset;
  font-size: 0.9rem;
  width: 80%;
}

.Dashboard-calendar-table th {
  border-bottom: 1px solid #d4d1d1e1;
  width: 75%;
  padding-right: 2rem;
  color: black;
}

.Dashboard-calendar-table p {
  white-space: nowrap;
}

.table-description-section {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.table-description-section-index {
  margin-right: 1.5rem;
  color: rgb(146, 200, 235);
  font-weight: 900;
}

.table-description-section-text {
  line-height: 0;
}

table h6 {
  font-weight: 700;
}

.table-description-section-text p {
  color: rgb(194, 194, 194);
}

.Dashboard-calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  padding: 2rem 5rem;
  width: 100%;
}

.Dashboard-calendar-container {
  padding: 2rem 15rem;
}

@media all and (max-width: 1024px) {
  .Dashboard-overview-card {
    height: 15vw;
    width: 15vw;
  }

  .Dashboard-overview-card h1 {
    font-size: 2rem;
  }

  .Dashboard-overview-card p {
    font-size: 0.9rem;
  }

  .Dashboard-calendar-table {
    margin: auto;
    width: 100%;
  }

  .Dashboard-calendar-container {
    padding: 1rem 5rem;
  }
}

@media all and (max-width: 750px) {
  .Dashboard-overview-card-container {
    flex-direction: column;
    align-items: start;
  }

  .Dashboard-overview-card {
    flex-direction: column;
    margin: 0.1rem;
    padding: 0.5rem;
    height: 7rem;
    width: 100%;
  }

  .Dashboard-overview-card h1 {
    font-size: 1.6rem;
  }

  .Dashboard-overview-card p {
    font-size: 0.8rem;
  }

  .Dashboard-calendar-container {
    padding: 1rem;
  }
}

@media all and (max-width: 605px) {
  .Dashboard {
    position: relative;
    width: 100% !important;
    height: 100vh;
    padding: 4rem 1rem;
  }

  .Dashboard-calendar-container {
    display: none;
  }

  .Dashboard-overview-section {
    margin-top: 5rem;
  }
}
