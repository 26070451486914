.Sidebar {
  z-index: 10;
  position: fixed;
  height: 100vh;
  background-color: white;
  box-shadow: 1px 0 5px 0px #d4d1d1e1;
  width: 5rem;
}

.Sidebar-menu {
  margin-top: 3rem;
  text-align: center;
}

.nav-brand {
  color: rgb(146, 200, 235) !important;
  font-weight: bold;
}

.nav-link {
  margin: 1rem 1rem;
  color: #222222ad;
}

button {
  border: none;
  background-color: white;
}

button:focus {
  outline: none;
}

.active {
  background-color: rgb(146, 200, 235) !important;
}

.nav-link:hover {
  color: rgb(89, 175, 233) !important;
}

.content {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 5rem;
  padding: 0;
  width: calc(100% - 5rem);
}

.Navbar-logout {
  z-index: 10;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
  margin: 1rem;
}

.Navbar-logout p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 1rem;
}

.standard-btn {
  background-color: rgb(146, 200, 235);
  color: white;
  transition: all 0.2s ease-in-out;
}

.logout-btn {
  background-color: rgb(169, 0, 0);
  color: white;
  transition: all 0.2s ease-in-out;
}

.logout-btn:hover {
  background-color: red;
  color: white;
}

@media all and (max-width: 1024px) {
  .Sidebar {
    z-index: 10;
    position: fixed;
    height: 4rem;
    width: 100%;
  }

  .nav-brand {
    display: none;
  }

  .Sidebar-menu {
    position: fixed;
    margin: auto;
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
  }

  .Sidebar-menu-links {
    position: relative !important;
    display: flex;
    flex-direction: row !important;
  }

  .nav-link {
    margin: 1rem 0.2rem;
  }

  .Sidebar button {
    padding-top: 0 !important;
    height: 4rem;
    width: 4rem;
  }

  .content {
    margin-left: 0;
    width: 100%;
  }
}
