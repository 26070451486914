.Form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  height: 100%;
  width: 90%;
}

.Form-bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Form-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Form-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  height: 10rem;
  width: 10rem;
  background-color: white;

  color: black;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 3%;
  transition: all 0.2s ease-in-out;
}

.Form-card h1 {
  font-weight: 900;
  font-size: 4rem;
}

.Form-card p {
  position: absolute;
  bottom: 0;
}

.Form-card:hover {
  transform: scale(1.1);
  box-shadow: 10px 10px 37px -16px rgba(212, 209, 209, 1);
  color: rgb(146, 200, 235);
}

/* Custom Form Style */
.Form-title {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: left;
  padding-bottom: 44px;
}

.Form-section-1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.Form-section-1-input {
  display: flex;
  flex-direction: column;
  margin: 0 5rem 0 0;
  width: 100%;
}

.Form-input-container {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 13px;
  margin-bottom: 65px;
  font-weight: 400;
}

.Form-label {
  padding-left: 5px;
  font-size: 0.9rem;
  color: #999999;
  line-height: 1.5;
}

.Form-input {
  display: block;
  margin-top: 2rem;
  padding: 0 1rem;
  width: 100%;
  background: transparent;
  border: none;
  color: #555555;
  font-size: 1rem;
  line-height: 1.2;
}

.Form-input textarea {
  display: block;
  margin-top: 2rem !important;
  padding: 0 1rem;
  width: 100%;
  background: transparent;
  border: none;
  color: #555555;
  font-size: 1rem;
  line-height: 1.2;
}
.Form-input:hover + .Form-input-focus:before {
  width: 100%;
}

.Form-input:focus {
  outline: none;
}

.Form-input:focus::placeholder {
  color: transparent;
}

.Form-input:focus + .Form-input-focus:before {
  width: 100%;
}

.Form-input-focus {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.Form-input-focus:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.5s ease-in-out;
  background: #2e384f;
}

.container-contact100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: -25px;
}

.contact100-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #2e384f;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  transition: all 0.4s;
  box-shadow: 0 10px 30px 0px rgb(84, 163, 202);
}

.contact100-form-btn:focus {
  outline: none;
}

.contact100-form-btn i {
  transition: all 0.4s;
}

.contact100-form-btn:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

.contact100-form-btn:hover i {
  transform: translateX(10px);
}

.animate-sending:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.Form-error-message {
  position: relative;
  top: -3rem;
  font-size: 0.8rem;
  color: red;
  font-weight: bold;
}
