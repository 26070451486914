.table-button-hover {
  transition: all 0.2s ease-in-out;
}

.table-button-hover:hover {
  color: rgb(146, 200, 235);
}

@media all and (max-width: 1024px) {
  .hide-column-mobile {
    display: none !important;
  }
}
