.FormSale {
  margin: 2rem;
}

.FormSale-category {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.FormSale-category-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  margin: 3rem;
  font-weight: 900;
  border: 1px solid #cecece;
  border-radius: 3%;
  transition: all 0.2s ease-in-out;
}

.FormSale-category-card:hover {
  transform: scale(1.1);
  box-shadow: 10px 10px 37px -16px rgba(212, 209, 209, 1);
  color: rgb(146, 200, 235);
}

.product-selected {
  transform: scale(1.1);
  box-shadow: 10px 10px 37px -16px rgba(212, 209, 209, 1);
  background-color: rgb(146, 200, 235);
  color: white;
}
