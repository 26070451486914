.Customer {
  height: 100%;
  width: 100%;
}

.Customer-container {
  background-color: white;
}

.Customer-top-section {
  margin: 1rem;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: #f6f6f6;
}

.Customer-top-section h6 {
  margin: 1rem;
  font-size: 2rem;
  font-weight: 900;
}

.Customer-left-section {
  padding: 1rem;
  width: 100%;
}

.Customer-left-section-text-container form {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.Customer-left-form-section {
  display: flex;
  flex-direction: column;
}

.Customer-left-form-section label {
  font-weight: 900;
  margin: 0;
}

.Customer-left-form-section input {
  width: 90%;
  background-color: white;
  border: none;
  border-bottom: 1px solid #fefefe;
}

.Customer-left-form-section input:disabled {
  background-color: #c9c9c9;
}

.Customer-edit {
  display: flex;
  flex-direction: row;
}

.Customer-edit-btn {
  margin: 1rem;
  width: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.Customer-edit-btn button {
  margin-right: 1rem;
}

.Customer-edit .edit {
  background-color: rgb(146, 200, 235);
  border: 1px solid rgb(146, 200, 235);
  transition: all ease-in-out 0.2s;
}

.Customer-edit .edit:hover {
  transform: scale(1.05);
  background-color: rgb(61, 168, 240);
  border: 1px solid rgb(61, 168, 240);
}

.Customer-right-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

.Customer-right-section .active {
  background-color: #f6f6f6 !important;
  color: black !important;
}

.note-card {
  margin: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  background-color: #fafafa;
}

.note-card .close {
  font-size: 0.9rem !important;
}

.note-card p {
  font-size: 0.9rem;
  margin: 0;
  font-weight: normal;
}

.Customer-purchase-section {
  margin: 1rem;
}
