.Login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 2rem;
  height: 100vh;
  background-color: #f3f3f3;
  z-index: 100;
}

.Login-card {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 5rem 1rem;
  width: 30%;
  background-color: white;
  border-radius: 20px;
}

.Login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  width: 90%;
}

.Login-form-input {
  margin: 0.5rem;
  width: 100%;
  height: 3rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}

.Login-form-input:focus {
  outline: none;
}

.Login-form-button {
  margin: 2em;
  padding: 0.5rem 2rem;
  background-color: rgb(84, 163, 202);
  color: white;
  font-weight: bold;
}

@media all and (max-width: 1024px) {
  .Login-card {
    padding: 8rem 2rem;
    width: 60%;
  }
}

@media all and (max-width: 605px) {
  .Login-card {
    padding: 10rem 1rem;
    width: 90%;
  }
}
