.WorkOrder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: calc(100vw-3rem);
  background-color: #f6f6f6;
}

.WorkOrder h5 {
  padding-top: 2rem;
  padding-left: 2rem;
}

.WorkOrder-container {
  margin: 1rem;
  background-color: white;
  box-shadow: 10px 10px 10px -5px rgba(199, 199, 199, 1);
}

.WorkOrder-back-button {
  margin: 1rem;
}

.WorkOrder-card-container-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1rem 0;
  padding: 0 3rem;
  width: 100%;
  background-color: white;
  transition: all 0.2s ease-in-out;
  color: black;
}

.WorkOrder-card-details {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding-left: 1rem;
}

.WorkOrder-card-container-card {
  padding: 3rem 1rem 0 1rem;
}

.WorkOrder-card-container-card span {
  font-weight: bold;
}

.WorkOrder-card-payment-section {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom: 2px solid rgb(128, 128, 128);
}

.WorkOrder-card-balance {
  display: flex;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
}

.WorkOrder-card-balance p {
  margin: 0;
}

.WorkOrder-card-button {
  display: flex;
  align-items: center;
  margin-left: 3rem;
  padding: 0 !important;
  width: 100%;
}

.WorkOrder-card-button button {
  font-size: 0.8rem;
}

.WorkOrder-card-status-list {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom: 2px solid rgb(128, 128, 128);
}

.WorkOrder-card-title {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0.5rem 2rem;
  font-weight: bold;
}

.WorkOrder-card-status-list span {
  font-weight: bold;
}

.WorkOrder-card-status-list ul {
  position: relative;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}

.WorkOrder-card-status-list li {
  list-style-type: none;
  margin: 0.1rem;
  padding: 0.4rem;
  background-color: rgb(195, 194, 194);
  border-radius: 25px;
  font-size: 0.8rem;
  transition: all 0.2s ease-in-out;
}

.WorkOrder-card-status-list li:hover {
  background-color: rgb(158, 158, 158);
}

.WorkOrder-active-ready {
  background-color: rgb(0, 207, 0) !important;
  color: white;
  font-weight: bold;
}

.WorkOrder-active-door {
  background-color: rgb(28, 115, 255) !important;
  color: white;
  font-weight: bold;
}

.WorkOrder-active-prep {
  background-color: rgb(22, 75, 158) !important;
  color: white;
  font-weight: bold;
}

.WorkOrder-active-ship {
  background-color: rgb(21, 126, 21) !important;
  color: white;
  font-weight: bold;
}

.WorkOrder-active-pending-parts {
  background-color: #ebe424 !important;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.WorkOrder-active-pending-paint {
  background-color: orange !important;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.WorkOrder-active-processed {
  background-color: #6b6b6b !important;
  color: white;
  font-weight: bold;
}

.WorkOrder-card-status-handle-buttons-container {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: white;
  opacity: 0.96;
  height: 100%;
  width: 100vw;
}

.WorkOrder-card-status-handle-buttons-container h1 {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.WorkOrder-card-status-handle-buttons button {
  margin: 5rem;
  font-size: 15rem;
  filter: grayscale(0.7);
  transition: all 0.2s ease-in-out;
}

.WorkOrder-card-status-handle-buttons button:hover {
  filter: grayscale(0);
  transform: scale(1.1);
}

.WorkOrder-door-render {
  width: 100%;
  height: 100%;
  display: flex;
}

/* NOTES SECTION */
.WorkOrder-notes-section {
  margin: 1rem;
  padding: 0.1rem;
  background-color: white;
  box-shadow: 10px 10px 10px -5px rgba(199, 199, 199, 1);
}

.WorkOrder-status-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.WorkOrder-status {
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8rem;
  cursor: pointer;
}

.WorkOrder-status i {
  margin: 2rem;
  font-size: 15rem;
  color: rgb(197, 197, 197);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.WorkOrder-status i:hover {
  color: green;
  font-weight: bold;
}

.fa-check-circle:hover {
  color: green !important;
  font-weight: bold;
}

.WorkOrder-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 0;
  border-top: 2px solid rgb(128, 128, 128);
}

.WorkOrder-buttons button {
  margin: 0 0.5rem;
}

.WorkOrders-filters-container-filter:hover {
  background-color: rgb(158, 158, 158);
}

.WorkOrder-notes-form {
  margin: 2rem 2rem 0 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.WorkOrder-notes-form-button {
  margin-bottom: 2rem;
  padding: 0;
  width: 100%;
}

.WorkOrder-notes-form button {
  background-color: rgb(84, 163, 202);
  color: white;
  font-weight: bold;
}

.WorkOrder-notes-form textarea {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 1rem;
  padding: 1rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(218, 218, 218);
}

.WorkOrder-notes-form textarea:focus {
  outline: none;
  border: 2px solid rgb(84, 163, 202);
}

.WorkOrder-delete-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.WorkOrder-delete-note {
  color: red;
}

.WorkOrder-notes-container {
  height: 100%;
}

.WorkOrder-notes-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem 2rem 2rem;
  padding: 0.2rem 0;
  border-bottom: 1px solid rgb(214, 214, 214);
  white-space: pre-wrap;
}

@media all and (max-width: 1024px) {
  .WorkOrder {
    width: 100vw;
  }

  .WorkOrder-container {
    margin: 0.5rem;
  }

  .WorkOrder-card-status-list {
    align-items: start;
    flex-direction: column;
    overflow-x: scroll;
  }

  .WorkOrder-card-status-list ul {
    display: flex;
  }

  .WorkOrder-card-status-list li {
    margin: 0.1rem;
    padding: 0.4rem;
    border-radius: 25px;
    font-size: 0.8rem;
    width: 5rem;
  }

  .WorkOrder-card-status-handle-buttons-container h1 {
    font-size: 1.4rem;
  }

  .WorkOrder-card-status-handle-buttons button {
    margin: 3rem;
    font-size: 12rem;
  }
}

@media all and (max-width: 605px) {
  .WorkOrder-card-status-handle-buttons-container h1 {
    font-size: 1.1rem;
  }

  .WorkOrder-card-status-handle-buttons button {
    margin: 2rem;
    font-size: 8rem;
  }

  .WorkOrder-card-container-card {
    flex-direction: column;
    margin: 3rem 0;
    padding: 0 1rem;
  }

  .WorkOrder-door-render {
    justify-content: start;
    margin-top: 1rem;
    padding-top: 3rem;
    border-top: 2px solid rgb(128, 128, 128);
    overflow-x: scroll;
  }
}
