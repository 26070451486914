.WorkOrders {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  width: calc(100vw-3rem);
  background-color: #f6f6f6;
}

.WorkOrders-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 50%;
  width: 45rem;
}

.WorkOrders-searchbar-container {
  width: 100%;
}

.WorkOrders-searchbar-container input {
  margin: 1rem 0;
  padding: 0.8rem 2rem;
  width: 100%;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 25px;
  transition: all 0.1s ease-in-out;
}

.WorkOrders-searchbar-container input:hover {
  border: 1px solid rgb(148, 148, 148);
  transform: scale(1.0005);
}

.WorkOrders-searchbar-container input:focus {
  outline: none;
  border: 1px solid rgb(98, 98, 98);
  transform: scale(1.0005);
}

.WorkOrders-filters-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.WorkOrders-filters-container-filter {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 7rem;
  border-radius: 25px;
  background-color: rgb(195, 194, 194);
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.active-all {
  background-color: rgb(146, 200, 235);
  color: white;
}

.active-ready {
  background-color: rgb(0, 207, 0);
  color: white;
}

.active-door {
  background-color: rgb(28, 115, 255);
  color: white;
}

.active-prep {
  background-color: rgb(22, 75, 158);
  color: white;
}

.active-pending-parts {
  background-color: #ebe424;
  color: rgb(255, 255, 255);
}

.active-pending-paint {
  background-color: orange;
  color: rgb(255, 255, 255);
}

.active-ship {
  background-color: rgb(21, 126, 21);
  color: white;
}

.active-processed {
  background-color: #6b6b6b;
  color: white;
}

.active-completed {
  background-color: rgb(222, 222, 222);
  color: white;
}

.WorkOrders-filters-container-filter:hover {
  background-color: rgb(158, 158, 158);
}

.WorkOrders-card-container-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 2rem;
  width: 100%;
  height: 14rem;
  background-color: white;
  box-shadow: 10px 10px 10px -5px rgba(199, 199, 199, 1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: black;
}

.WorkOrders-card-container-card:hover {
  text-decoration: none;
  transform: scale(1.01);
  color: black;
}

.WorkOrders-card-container-card-status {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.8rem;
  border-radius: 25px;
  background-color: rgb(195, 194, 194);
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.WorkOrders-card-container-card h6 {
  margin: 0 0 0.2rem 0;
  font-weight: bold;
  font-size: 1.3rem;
}

.WorkOrders-card-container-card p {
  margin: 0;
}

@media all and (max-width: 1024px) {
  .WorkOrders-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 50%;
    width: 35rem;
  }

  .WorkOrders-filters-container {
    overflow-x: scroll;
  }

  .WorkOrders-filters-container-filter {
    margin-top: 0.5rem;
    margin-right: 0.8rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  .WorkOrders-card-container-card {
    height: 18rem;
  }
}

@media all and (max-width: 605px) {
  .WorkOrders {
    width: 100vw;
  }

  .WorkOrders-container {
    width: 100vw;
    padding: 0.5rem;
  }

  .WorkOrders-card-container-card {
    flex-direction: column;
    margin-top: 2rem;
    padding: 2rem;
    height: 20rem;
  }

  .WorkOrders-card-container-card-status {
    margin: 0rem;
    border-radius: 25px;
    width: 100%;
  }
}

.btn {
  background-color: rgb(146, 200, 235);
  color: white;
  transition: all 0.2s ease-in-out;
}