.SaleDoorRender {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  white-space: nowrap;
}

.SaleDoorRender-top-section {
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0;
  padding: 0;
  width: 100%;
}

.SaleDoorRender-bottom-section {
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0;
  padding: 0;
  width: 100%;
}

.SaleDoorRender-image-text {
  z-index: 30;
  padding: 0.1rem 0.5rem;
  color: black;
  background-color: rgb(206, 206, 206);
  border-radius: 30px;
  opacity: 0.8;
  font-size: 1.2rem;
}

.SaleDoorRender-measurements {
  z-index: 30;
  padding: 0.1rem 0.5rem;
  color: white;
  background-color: rgb(84, 163, 202);
  border-radius: 30px;
  opacity: 0.9;
  font-size: 1.2rem;
}

.SaleDoorRender-image-red-text {
  padding: 0.2rem 0.5rem;
  color: white;
  background-color: red;
  border-radius: 25px;
  opacity: 0.75;
  font-weight: bold;
  text-align: center;
}

.SaleDoorRender-image-red-text p {
  margin: 0;
}

.frameStyle {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.transomStyle {
  margin: 0;
  padding: 0;
  border: 1px solid black;
}

.doorStyle {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  border: 1px solid black;
}

.sidelightStyle {
  border: 1px solid black;
}

.sidelightStyle-insert {
  white-space: normal;
}

.SaleDoorRender-door-notes {
  width: 100%;
  margin-top: 3rem;
}

.SaleDoorRender-door-notes li {
  width: 100%;
  margin-top: 3rem;

  white-space: normal;
  word-break: break-all !important;
}

.SaleDoorRender-door-notes i {
  transition: all 0.2s ease-in-out;
}

.fa-minus:hover {
  color: red !important;
}

.fa-star:hover {
  color: rgb(84, 163, 202) !important;
}
.SaleDoorRender-door-notes i:hover {
  color: rgb(84, 163, 202);
}

@media all and (max-width: 1024px) {
  .SaleDoorRender {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    padding: 2rem;
    white-space: nowrap;
  }
}
