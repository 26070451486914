.Customers {
  position: relative;
  margin: 0;
  padding: 3rem;
  background-color: #f3f3f3;
}

h4 {
  color: rgb(146, 200, 235);
  font-weight: 700;
}

.Customers-searchbar {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem 3rem;
  width: 100%;
  background-color: white;
  border: none;
}

.Customers-searchbar input {
  padding: 0.1rem 2rem;
  width: 95%;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}

.Customers-searchbar input:focus {
  border: 1px solid #f3f3f3;
  outline: none;
  padding: 0.4rem;
}

.Customers-searchbar button {
  width: 5%;
  background-color: white;
  border: none;
}

.Customers-overview-section {
  width: 100%;
}

.Customers-overview-card-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: #f3f3f3 !important;
  margin-bottom: 0;
  width: 100%;
}

.Customers-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 
DELETE? - NOT CLASS USED

.Customers-table {
  margin: 0 auto;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 0.8rem;
  width: 80%;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  empty-cells: show;
}

.Customers-table h6 {
  font-weight: 700;
  font-size: 0.7rem;
}

.Customers-table thead tr {
  background-color: rgb(146, 200, 235);
  color: white;
  text-align: left;
  font-weight: 900;
}

.Customers-table th,
.Customers-table td {
  padding: 1rem;
}

.Customers-table tbody tr {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.Customers-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.Customers-table tbody tr:last-child {
  border-bottom: 2px solid rgb(200, 225, 241);
}

.Customers-table tbody tr:hover {
  background-color: rgb(200, 225, 241);
} */

.Customers-table-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  padding: 1rem 5rem;
  width: 100%;
}

.modal-dialog {
  z-index: 1070 !important;
}

@media all and (max-width: 1024px) {
  .Customers {
    padding: 4rem 0.2rem 0.2rem 0.2rem;
  }
}
